module.exports = [{
      plugin: require('/Users/francoisbulteau/Dev/gitbrick-front/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.gitbrick.com","exclude":["/checkout/confirmed","/checkout/payment-failed","/404","/contributions"]},
    },{
      plugin: require('/Users/francoisbulteau/Dev/gitbrick-front/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/francoisbulteau/Dev/gitbrick-front/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143872677-1","head":false},
    },{
      plugin: require('/Users/francoisbulteau/Dev/gitbrick-front/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
