// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/francoisbulteau/Dev/gitbrick-front/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/francoisbulteau/Dev/gitbrick-front/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-confirmed-js": () => import("/Users/francoisbulteau/Dev/gitbrick-front/src/pages/checkout/confirmed.js" /* webpackChunkName: "component---src-pages-checkout-confirmed-js" */),
  "component---src-pages-checkout-payment-failed-js": () => import("/Users/francoisbulteau/Dev/gitbrick-front/src/pages/checkout/payment-failed.js" /* webpackChunkName: "component---src-pages-checkout-payment-failed-js" */),
  "component---src-pages-contributions-js": () => import("/Users/francoisbulteau/Dev/gitbrick-front/src/pages/contributions.js" /* webpackChunkName: "component---src-pages-contributions-js" */),
  "component---src-pages-index-js": () => import("/Users/francoisbulteau/Dev/gitbrick-front/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

